import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { SortMenuComponent } from './components/sort-menu/sort-menu.component';
import { JoinNamesPipe } from './pipes/join-names.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { HeaderAdminComponent } from './components/header-admin/header-admin.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FilterMenuComponent,
    SortMenuComponent,
    JoinNamesPipe,
    FileSizePipe,
    TitleCasePipe,
    OrdinalPipe,
    NotAuthorizedComponent,
    HeaderAdminComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModalModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
  ],
  exports: [
    HeaderComponent,
    HeaderAdminComponent,
    FooterComponent,
    SidebarComponent,
    FilterMenuComponent,
    SortMenuComponent,
    JoinNamesPipe,
    FileSizePipe,
    TitleCasePipe,
    OrdinalPipe,
  ]
})
export class SharedModule { }
